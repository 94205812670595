const players = $('.player')

window.secondsToMs = d => {
    d = Number(d)
    let m = Math.floor(d % 3600 / 60)
    let s = Math.floor(d % 3600 % 60)

    let mDisplay = m > 0 ? m : 0
    let sDisplay = s > 0 ? s : 0

    mDisplay = mDisplay < 10 ? '0' + mDisplay : mDisplay
    sDisplay = sDisplay < 10 ? '0' + sDisplay : sDisplay

    return mDisplay + ':' + sDisplay
}

players.each(function(){
    const player = $(this)
    const btn = player.find('.audio-btn')
    const progressBar = player.find('.progress')
    const durationText = player.find('.duration')
    const audio = player.find('audio')[0]

    let playerInterval = ''

    btn.on('click', e => {

        if(player.hasClass('playing')){
            audio.pause()
            player.removeClass('playing')
            clearInterval(playerInterval)
        }else{
            audio.play()
            player.addClass('playing')

            if(audio.currentTime == audio.duration)
                audio.currentTime = 0

            playerInterval = setInterval(() => {
                const currentTime = audio.currentTime
                const duration = audio.duration
                const progress = (currentTime / duration) * 100

                if(progress > 50){
                    player.siblings('.question-content').addClass('clickable')
                }

                progressBar.width(progress + '%')
                durationText.text(secondsToMs(Math.round(currentTime)))

                if(progress == 100){
                    audio.pause()
                    player.removeClass('playing')
                    clearInterval(playerInterval)
                    player.siblings('.disabled').removeClass('disabled')
                }
            }, 50)
        }

    })

})