let allowed_operators = [700,701,702,703,704,705,706,707,708,709,747,750,751,760,761,762,763,764,771,775,776,777,778]

function display_error(input, message){
    input.siblings('.error-message').remove()
}

$.fn.check = function() {    
    let errors = 0

    let val = $.trim(this.val())
    let isMasked = this[0].mask ? true : false

    if(val) {
        this.removeClass('error')
    }else{
        errors++
        this.addClass('error')
    }

    if(isMasked && !errors) {
        if(val.indexOf('_') == -1) {
            let operator = parseInt(val.substr(4,3))

            if(allowed_operators.indexOf(operator) == -1) {
                errors++
                this.addClass('error')
            } else {
                this.removeClass('error')
            }

        }else{
            errors++
            this.addClass('error')
        }
    }
    
    this.on('input', () => {
        this.removeClass('error')
    })

    if(errors){
        display_error(this, 'Ошибка')
    }

    return errors
}

$('input').on('input change', function(){
    $(this).removeClass('error')
})