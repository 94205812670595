$('#create_flow').click(function(){
    let elem = $(this)
    let input = $('#flow_name')
    let name = $.trim(input.val())

    if(name) {
        elem.attr('disabled', true)

        requestAjax('add_flow', name, () => {
            window.location.reload()
        })
    }
})

$('.delete-flow').click(function(e){
    let elem = $(this)
    let flow = elem.data('flow')
    let name = elem.data('name')

    if(confirm('Вы уверены что хотите удалить поток "' + name + '"')) {
        requestAjax('remove_flow', flow, () => {
            window.location.reload()
        })
    }

    e.preventDefault()
})