let body = $('.testing-body')
let footer = $('.testing-footer')
let steps = body.find('.step')
let quizz = {
    id: 0,
    body: body,
    footer: footer,
    steps: steps,
    data: {},
    allow: 1,
    ended: 0,
    active: null,
    refresh: () => {
        quizz.body.height(quizz.active[0].clientHeight)
    },
    set_value: (elem, value, allow = 1) => {
        let step = elem.parents('.step')

        step[0].res = {
            value,
            allow
        }
    },
    next: () => {
        let current = quizz.step
        let next_step = current + 1
        let next = quizz.steps.eq(next_step)
        let btn = next.find('.next-step')

        $('audio').each(function(){
            let elem = $(this)

            elem[0].pause()
        })

        if(next.length) {
            let play_btn = next.find('.audio-btn')
            quizz.step = next_step

            if(play_btn.length) {
                play_btn.click()
            }

            if(next.hasClass('last-step')) {
                requestAjax('end_quizz', quizz.id, () => {})
            }

            if(next.data('key') == 'mixes') {
                let first_audio = next.find('audio').eq(0)
                let audios = next.find('audio')

                audios.each(function(){
                    let audio = $(this)

                    audio[0].addEventListener('ended', () => {
                        let wrapper = audio.parents('.variant')
                        let next = wrapper.next('.variant')

                        if(next.length) {
                            let next_audio = next.find('audio')[0]
                            next_audio.play()
                            next_audio.playingEvent()
                        } else {
                            btn.attr('disabled', false)
                        }
                    })

                    audio[0].playingEvent = () => {
                        let wrapper = audio.parents('.variant')
                        let progressBar = wrapper.find('.progress')

                        wrapper.addClass('playing')
                        
                        let playerInterval
                        playerInterval = setInterval(() => {
                            const currentTime = audio[0].currentTime
                            const duration = audio[0].duration
                            const progress = (currentTime / duration) * 100
            
                            progressBar.width(progress + '%')
            
                            if(progress == 100){
                                audio.pause()
                                player.removeClass('playing')
                                clearInterval(playerInterval)
                            }
                        }, 50)
                    }
                })

                first_audio[0].play()
                first_audio[0].playingEvent()
            }
        }
    }
}

Object.defineProperty(quizz, 'step', {
    set: (step) => {
        if(quizz.steps.eq(step)) {
            quizz.steps.removeClass('active')
            this.current_step = step
            quizz.active = quizz.steps.eq(step)
            quizz.active.addClass('active')
            quizz.refresh()
            quizz.footer.html(step + 1 + '/' + quizz.steps.length)
        }

        return step
    },
    get: () => {
        return this.current_step
    }
})

quizz.step = 0

window.quizz = quizz