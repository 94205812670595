// LANG
let lang_step = $('.step[data-key="lang"]')

Object.defineProperty(lang_step[0], 'selected', {
    set: (data) => {
        this.selected_data = data
        quizz.next()
        return data
    },
    get: (data) => {
        return this.selected_data
    }
})

lang_step.find('.lang').click(function(){
    let elem = $(this)
    let value = elem.index()
    let allow = 1

    lang_step[0].selected = {
        value,
        allow
    }
})

// PHONE
let phone_step = $('.step[data-key="phone"]')
let phone_next = phone_step.find('.next-step')

Object.defineProperty(phone_step[0], 'selected', {
    set: (data) => {
        this.selected_data = data
        quizz.next()
        return data
    },
    get: (data) => {
        return this.selected_data
    }
})

phone_next.click(function(){
    let elem = $(this)
    let phone = $('#phone')
    
    if(!phone.check()) {
        phone = phone[0].mask.unmaskedValue

        elem.attr('disabled', true)
        requestAjax('get_user_data', phone, (data) => {
            if(data) {
                data = JSON.parse(data)
                let userdata = data.user
                let answers = data.answers
                let allow = userdata.allow
                let keys = ['city', 'age', 'gender', 'periodicity', 'radio', 'fav', 'mix_1', 'mix_2', 'mix_3', 'mix_4']

                if(parseInt(userdata.ended)) {
                    let steps = $('.step:not(.last-step)')
                    let step = $('.last-step')

                    steps.remove()
                    step.find('h4').text('Вы уже прошли опрос')
                    quizz.steps = $('.step')
                    quizz.step = 0
                } else {
                    if(answers.length) {
                        for(let answer of answers) {
                            let elem = $('.step.audio[data-name="' + answer + '"]')
    
                            elem.remove()
                        }
    
                        quizz.steps = $('.step')
                    }
    
                    for(let key of keys) {
                        let value = userdata[key]
    
                        if(value != null) {
                            let step = $('.step[data-key="' + key + '"]')
    
                            step[0].selected = {
                                value,
                                allow
                            }
                        }
                    }
    
                    quizz.id = userdata.id
                    quizz.next()
                }

            } else {
                requestAjax('add_member', phone, (id) => {
                    quizz.id = id

                    phone_step[0].selected = {
                        value: phone,
                        allow: 1
                    }
                })
            }
        })
    }

})

// SELECT
let select_steps = $('.step[data-type="select"]')

select_steps.each(function(){
    let elem = $(this)
    let key = elem.data('key')
    let variants = elem.find('.variant')
    let btn = elem.find('.next-step')
    let value = []
    let allow = null

    Object.defineProperty(elem[0], 'selected', {
        set: (data) => {

            if(data.value !== '') {
                this.selected_data = data
                quizz.next()
    
                if(Array.isArray(data.value)) {
                    data.value = data.value.join(',')
                }
    
                requestAjax('save_member_data', {
                    id: quizz.id,
                    key: key,
                    data: data.value,
                    allow: data.allow,
                }, () => {})
    
                if(key == 'radio') {
                    let fav_step = $('.step[data-key="fav"]')
                    let fav_variants = fav_step.find('.variant')
    
                    fav_step[0].selected = {
                        value: '',
                        allow: 1
                    }
    
                    fav_variants.removeClass('active').hide()
                    let selected = data.value.split(',')
    
                    for(let index of selected) {
                        fav_step.find('.variant[data-key="' + index + '"]').show()
                    }

                    quizz.refresh()
                }
    
                return data
            }
        },
        get: (data) => {
            return this.selected_data
        }
    })

    variants.click(function(){
        let variant = $(this)

        if(elem.hasClass('few')) {
            variant.toggleClass('active')
            value = []

            let selected = elem.find('.active')
            
            if(selected.length) {
                allow = 0
                selected.each(function(){
                    let item = $(this)
                    let is_allowed = item.data('allow')

                    if(is_allowed) {
                        allow = 1
                    }

                    value.push(item.data('key'))
                })

                btn.attr('disabled', false)
            } else {
                btn.attr('disabled', true)
            }
        } else {
            let siblings = variant.siblings()

            variant.addClass('active')
            siblings.removeClass('active')
    
            value = [variant.data('key')]
            allow = variant.data('allow')
            
            if(elem.data('key') != 'mixes') {
                btn.attr('disabled', false)
            }
        }
    })

    btn.click(function(){
        elem[0].selected = {value, allow}
    })
})

// GENDER
let gender_step = $('.step[data-type="gender"]')
let gender_next = gender_step.find('.next-step')
let gender_select = gender_step.find('.gender')
let gender_selected = 0
let gender_allow = 1

Object.defineProperty(gender_step[0], 'selected', {
    set: (data) => {
        this.selected_data = data
        quizz.next()

        requestAjax('save_member_data', {
            id: quizz.id,
            key: 'gender',
            data: gender_selected,
            allow: gender_allow,
        }, () => {})

        return data
    },
    get: (data) => {
        return this.selected_data
    }
})

gender_select.click(function(){
    let elem = $(this)

    elem.addClass('active')
    elem.siblings().removeClass('active')
    gender_selected = elem.index()
    
    gender_next.attr('disabled', false)
})

gender_next.click(function(){
    gender_step[0].selected = {
        value: gender_selected,
        allow: gender_allow,
    }
})

// SKIP
let skip_step = $('.step.skip')
let skip_btn = skip_step.find('.next-step')

skip_btn.click(function(){
    quizz.next()
})

// AUDIO
let audio_steps = $('.step.audio')
if(audio_steps.length) {
    audio_steps.each(function(){
        let elem = $(this)
        let name = elem.data('name')
        let parent_variants = elem.find('.selects-parent .variant')
        let second_steps = elem.find('.second-step')
        let variants = elem.find('.second-step .variant')
        let answer_group = null
        let answer = null
        let btn = elem.find('.next-step')

        parent_variants.click(function(){
            let item = $(this)
            let index = item.index()

            answer_group = index

            parent_variants.removeClass('active')
            item.addClass('active')
            
            variants.removeClass('active')
            second_steps.hide()

            second_steps.eq(index).show()

            btn.attr('disabled', true)
            quizz.refresh()
        })

        variants.click(function(){
            let item = $(this)
            let index = item.data('name')

            answer = index

            variants.removeClass('active')
            item.addClass('active')

            btn.attr('disabled', false)
        })

        btn.click(function(){
            elem[0].selected = {
                member_id: quizz.id,
                track: name,
                answer: answer,
                answer_group: answer_group,
            }
        })

        Object.defineProperty(elem[0], 'selected', {
            set: (data) => {
                this.selected_data = data
                quizz.next()
        
                requestAjax('send_results', data, () => {})
        
                return data
            },
            get: (data) => {
                return this.selected_data
            }
        })
    })
}