function phone_mask(){
    var check = $('.phone-mask').length   

    if ( check > 0 ) {

        var items = document.getElementsByClassName('phone-mask')

        Array.prototype.forEach.call(items, function (element) {
            var phoneMask = new IMask(element, {
                mask: '+{7} (000) 000-00-00',
                lazy: false,  // make placeholder always visible
                placeholderChar: '_'     // defaults to '_'
            })

            element.mask = phoneMask
        })

        $('.phone-mask').each(function(){
            var elem = $(this)

            elem.attr('placeholder', elem.val())
        })

    }
    
}

function code_mask(){
    var check = $('.code-mask').length   

    if ( check > 0 ) {

        var items = document.getElementsByClassName('code-mask')

        Array.prototype.forEach.call(items, function (element) {
            var codeMask = new IMask(element, {
                mask: '0 0 0 0',
                lazy: false,  // make placeholder always visible
                placeholderChar: '_'     // defaults to '_'
            })
        })

        $('.code-mask').each(function(){
            var elem = $(this)

            elem.attr('placeholder', elem.val())
        })

    }
    
}

function price_mask(){
    var check = $('.price-mask').length   

    if ( check > 0 ) {

        var items = document.getElementsByClassName('price-mask')

        Array.prototype.forEach.call(items, function (element) {
            var priceMask = new IMask(element, {
                mask: Number,
                min: 0,
                max: 100000000,
                thousandsSeparator: ' '
            })

            element.mask = priceMask
        })

    }

}

function number_mask(){
    var check = $('.number-mask').length   

    if ( check > 0 ) {

        var items = document.getElementsByClassName('number-mask')

        Array.prototype.forEach.call(items, function (element) {
            var numberMask = new IMask(element, {
                mask: Number
            })

            element.mask = numberMask
        })

    }

}

function age_mask(){
    var check = $('.age-mask').length   

    if ( check > 0 ) {

        var items = document.getElementsByClassName('age-mask')

        Array.prototype.forEach.call(items, function (element) {
            var ageMask = new IMask(element, {
                mask: Number,
                max: 99,
                scale: 0
            })

            element.mask = ageMask
        })

    }

}

function date_mask(){
    var items = document.getElementsByClassName('date-mask')

    Array.prototype.forEach.call(items, function (element) {
        var dateMask = new IMask(element, {
            mask: Date,
            lazy: false,
            placeholderChar: '_'     // defaults to '_'
        })

        element.mask = dateMask
    })
}

$(document).ready(function () {

    phone_mask()
    code_mask()
    price_mask()
    number_mask()
    age_mask()

    $(document).on('input', '.name-mask', function(){
        var elem = $(this),
            val  = elem.val().replace(/[^a-zA-Z0а-яА-ЯГгҚқҒғӘәҢңӨөҰұҮүҺһІіЭэ\- ]/gi, ''),
            val  = val.substr(0, 40)        

        elem.val(val)
    })

})